<template>
  <div class="text-center">
    <v-select
      :value="classId"
      :items="items"
      label="Set active database"
      @change="switchDB"
      single-line
      name="dbswitcher"
      :loading="loading"
      hide-details
    ></v-select>
  </div>
</template>

<script>
export default {
  computed: {
    classId() {
      return this.$route.params.classId;
    }
  },
  data() {
    return {
      loading: false,
      items: []
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$axios
        .get("/database/get_db_list")
        .then((res) => {
          if (res.data) {
            this.items = res.data.db_list;
            if (res.data.active) {
              if (!this.classId) {
                // update url to active db
                this.$router.push({ params: { classId: res.data.active } });
              } else {
                // if accessed by url and differ from userprofile->active_db
                this.switchDB(this.classId, true);
              }
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    switchDB(classId, noPush = false) {
      this.$axios
        .put("/database/set_active_db", {
          classId: classId
        })
        .then(() => {
          if (!noPush) {
            this.$router.push({ params: { classId: classId } });
          }
        });
    }
  }
};
</script>
