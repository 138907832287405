<template>
  <div style="display: contents">Form for {{ classId }}</div>
</template>

<script>
export default {
  components: {},
  computed: {
    classId() {
      return this.$route.params.classId;
    }
  },
  data() {
    return {};
  },
  created() {
    this.openTM({ ccmsId: 321, projectId: 123 }, { aa: 33 });
  },
  methods: {
    openTM(ids, options) {
      let url = "";
      for (const [key, value] of Object.entries({ ...ids, ...options })) {
        url += `&${key}=${value}`;
      }
      console.log(url);
    }
  }
};
</script>
