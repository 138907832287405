<template>
  <div class="text-center">
    <v-menu open-on-hover offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Recent LNP Campaigns
        </v-btn>
      </template>

      <v-list>
        <template v-if="items.length > 0">
          <v-list-item v-for="(item, index) in items" :key="index" :to="item.to">
            <v-list-item-title>{{ item.campaignName }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item-group v-else>
          <v-list-item :disabled="true">
            <v-list-item-content>
              <v-list-item-subtitle class="text--disabled"
                >You have no recent campaigns</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  computed: {
    campaignId() {
      return this.$route.params.campaignId;
    }
  },
  created() {
    this.load();
  },
  data() {
    return {
      items: []
    };
  },
  methods: {
    load() {
      this.$axios
        .get("/lnp/get_recent_campaigns", {
          params: {
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          if (res.data) {
            this.items = res.data.map((c) => {
              return {
                campaignName: c.campaignName,
                to: { params: { classId: c.classId, campaignId: c.campaignId, tab: "info" } }
              };
            });
          }
        });
    }
  }
};
</script>
