<template>
  <v-main>
    <v-container :fill-height="fillHeight" fluid :class="!fillHeight || 'ma-0 pa-0'">
      <v-row v-if="!fillHeight" align="center" justify="center">
        <router-view />
      </v-row>
      <router-view v-else />
    </v-container>
    <NotificationBar></NotificationBar>
    <ConfirmationDialog ref="confirm"></ConfirmationDialog>
  </v-main>
</template>

<script>
import ConfirmationDialog from "@/components/util/ConfirmationDialog";
import NotificationBar from "@/components/util/NotificationBar";

export default {
  components: {
    ConfirmationDialog,
    NotificationBar
  },
  data() {
    return {};
  },
  computed: {
    fillHeight: {
      get() {
        return this.$route.meta.fillHeight;
      }
    }
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
  }
};
</script>
