<template>
  <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary darken-1" dark dense id="appToolbar">
    <v-app-bar-nav-icon @click.stop="setSidebar" /><router-link to="/">
      <v-btn icon>
        <v-avatar size="33">
          <img src="/favicon.ico" alt="UPS" />
        </v-avatar> </v-btn
    ></router-link>
    <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
      <span>{{ this.$store.state.auth.user.username }}</span>
    </v-toolbar-title>
    <!-- <v-text-field flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" label="Search" /> -->
    <v-spacer />

    <template v-if="$store.state.auth.user.rolename == 'telemarketer'">
      <v-divider class="mx-4" vertical></v-divider>
      <AssignedProjectDD />
      <v-divider class="mx-4" vertical></v-divider>
      <ScheduleCallbackBtn />
      <MessageHistoryBtn />
    </template>
    <template v-else-if="$route.name == 'LNP'">
      <RecentCampaigns />
    </template>
    <template v-else-if="$route.path.startsWith('/database/')">
      <DBSwitcher />
    </template>

    <v-divider
      v-if="$store.state.auth.user.rolename != 'telemarketer'"
      class="ml-3 mr-1"
      vertical
    ></v-divider>
    <v-btn icon @click="logout">
      <v-icon>mdi-power</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import AssignedProjectDD from "@/components/telemarketer/toolbar/AssignedProjectDD";
import MessageHistoryBtn from "@/components/telemarketer/toolbar/MessageHistoryBtn";
import ScheduleCallbackBtn from "@/components/telemarketer/toolbar/ScheduleCallbackBtn";
import RecentCampaigns from "@/components/lnp/RecentCampaigns";
import DBSwitcher from "@/components/database/DBSwitcher";

export default {
  components: {
    MessageHistoryBtn,
    ScheduleCallbackBtn,
    AssignedProjectDD,
    RecentCampaigns,
    DBSwitcher
  },
  data() {
    return {};
  },
  methods: {
    setSidebar() {
      this.$store.commit("toggleSidebar");
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/login");
      });
    }
  }
};
</script>
