<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <template v-if="!hideMenu">
      <Sidebar />
      <Toolbar />
    </template>
    <Content />
  </v-app>
</template>

<script>
import Sidebar from "@/components/core/Sidebar";
import Toolbar from "@/components/core/Toolbar";
import Content from "@/components/core/Content";

export default {
  name: "App",

  components: {
    Toolbar,
    Sidebar,
    Content
  },

  computed: {
    hideMenu: {
      get() {
        return this.$route.meta.hideMenu;
      }
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/ups.scss";
</style>

