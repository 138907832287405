<template>
  <v-navigation-drawer
    :expand-on-hover="!sidebar"
    :mini-variant="!sidebar"
    :clipped="$vuetify.breakpoint.lgAndUp"
    permanent
    app
  >
    <v-list dense expand nav id="sidebarNav">
      <template v-for="item in items">
        <v-list-group
          v-if="item.children"
          :key="item.name"
          append-icon="mdi-chevron-down"
          no-action
          :group="item.path"
        >
          <template v-slot:prependIcon>
            <template>
              <img
                v-if="item.custom_icon != null"
                :height="item.custom_icon.height"
                :width="item.custom_icon.width"
                :src="item.custom_icon.svg_path"
                class="customIcon"
              >
              <v-icon v-else>{{item.icon}}</v-icon>
            </template>
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-for="(child, i) in item.children">
            <v-list-group v-if="child.children" :key="i" no-action sub-group :value="false">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ child.name }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(child2, x) in child.children"
                :key="x"
                v-bind="linkBindings(child2)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ child2.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-item v-else :key="i" v-bind="linkBindings(child)">
              <v-list-item-icon v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ child.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>

        <v-list-item v-else :key="item.name" v-bind="linkBindings(item)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<style lang="scss">
#sidebarNav {
  .v-list-item {
    min-height: 32px !important;
  }
  .v-list-item__icon {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .customIcon {
    filter: opacity(.5);
  }
}
</style>

<script>
export default {
  data() {
    return {
      items: []
    };
  },
  computed: {
    sidebar: {
      get() {
        return this.$store.state.sidebar;
      },
      set() {}
    }
  },
  created() {
    this.items = this.$store.getters["auth/getMenu"];
  },
  methods: {
    linkBindings(service) {
      let path = service.path;
      if (service.apihost) {
        let domainName = this.$store.getters["auth/getApiHost"][service.apihost];
        path = domainName + path;
      }
      if (path && !path.startsWith("/")) {
        return {
          href: path,
          target: "_blank"
        };
      }
      return { to: path };
    }
  }
};
</script>
